
.modal-lg {
  width: 300px;
}

.AvatarCropper-base {
  text-align: center;
}

.AvatarCropper-base input[type=range] {
  display: inline-block;
}

.AvatarCropper-base input[type=range] {
  /*removes default webkit styles*/
  -webkit-appearance: none;
  /*fix for FF unable to apply focus style bug */
  border: 1px solid white;

  /*required for proper track sizing in FF*/
  width: 265px;
}
.AvatarCropper-base input[type=range]::-webkit-slider-runnable-track {
  width: 100px;
  height: 5px;
  background: #ddd;
  border: none;
  border-radius: 3px;
}
.AvatarCropper-base input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #454545;
  margin-top: -5px;
}
.AvatarCropper-base input[type=range]:focus {
  outline: none;
}
.AvatarCropper-base input[type=range]:focus::-webkit-slider-runnable-track {
  background: #ccc;
}

.AvatarCropper-base input[type=range]::-moz-range-track {
  width: 200px;
  height: 5px;
  background: #ddd;
  border: none;
  border-radius: 3px;
}
.AvatarCropper-base input[type=range]::-moz-range-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #454545;
}

/*hide the outline behind the border*/
.AvatarCropper-base input[type=range]:-moz-focusring{
  outline: 1px solid white;
  outline-offset: -1px;
}

.AvatarCropper-base input[type=range]::-ms-track {
  width: 200px;
  height: 5px;

  /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
  background: transparent;

  /*leave room for the larger thumb to overflow with a transparent border */
  border-color: transparent;
  border-width: 6px 0;

  /*remove default tick marks*/
  color: transparent;
}
.AvatarCropper-base input[type=range]::-ms-fill-lower {
  background: #777;
  border-radius: 10px;
}
.AvatarCropper-base input[type=range]::-ms-fill-upper {
  background: #ddd;
  border-radius: 10px;
}
.AvatarCropper-base input[type=range]::-ms-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #454545;
}
.AvatarCropper-base input[type=range]:focus::-ms-fill-lower {
  background: #888;
}
.AvatarCropper-base input[type=range]:focus::-ms-fill-upper {
  background: #ccc;
}
.AvatarCropper-base canvas {
  cursor: move;
}
