#navbar {
  margin-bottom: 20px;
  display: flex;
  margin: auto;
  padding: 0; }

#navbar > img {
  padding: 10px 0 10px 0;
  width: 30px; }

#navbar a {
  text-decoration: none;
  color: #337ab7 !important; }

#navbar > #breadcrumb {
  background-color: #f5f5f5;
  margin: 10px;
  padding: 0 10px 0 10px;
  height: 30px;
  font-size: 18px !important; }

#navbar .glyphicon {
  margin-right: 5px; }

#navbar .navbar-end {
  margin-left: auto;
  padding: 10px;
  padding-right: 0;
  cursor: pointer;
  display: table-cell;
  white-space: nowrap;
  vertical-align: middle; }

.navbar-end > button {
  text-decoration: none;
  color: #337ab7 !important;
  font-size: 18px !important;
  padding: 0; }

.navbar-end > div > a:active:hover {
  background-color: #e9ecef !important; }
