.body_modal.show {
  opacity: var(--bs-backdrop-opacity); }

.body_modal.fade {
  opacity: 0;
  transition: opacity 2s ease; }

.body_modal {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  z-index: var(--bs-backdrop-zindex);
  background-color: var(--bs-backdrop-bg); }