#avatar{
	width:202px;
	height:202px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
	border-radius: 100px;
	border: 1px solid #e7e7e7;
  position: relative;
}

#avatar>img{
  position: absolute;
  top: 0px;
  left: 0px;
	width:200px;
	height:200px;
	background:#FFF;
	opacity: 1;
	border-radius: 100px;
}

#avatar>.avatar-edit{
  opacity: 0;
  font-size: 50px;
}

#avatar:hover>img
{
	opacity:0.3;
	cursor: pointer;
}

#avatar:hover>.avatar-edit
{
	opacity: 1;
}
