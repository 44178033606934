div.css-1pahdxg-control {
  border-color: #99bddb;
  outline: 0;
  box-shadow: 0 0 0 1px rgb(51 122 183 / 25%);
}

div.css-1s2u09g-control {
  border-color: #ced4da;
}

div.css-xb97g8:hover {
  background-color: hsl(0, 0%, 90%);
  color: #000000;
}

div.css-xb97g8 {
  color: gray;
}

.form-tags {
  margin-top: 4px;
}

