.sort-icon.sort-none:after {
  content: '\F0DC';
}

.sort-icon:after {
  margin-left: 5px;
  color: hsl(20, 0%, 70%);
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sort-icon.sort-ascending:after {
  content: '\F0DD';
  
}

.sort-icon.sort-descending:after {
  content: '\F0DE';
}

.custom-thead tr>th:last-child {
  text-align: right
}

.custom-thead tr>th.sortable {
  cursor: pointer
}

.custom-thead tr>th.nonsortable {
  cursor: default
}