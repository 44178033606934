.users .filterbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
  padding-top: 30px
}

.users .filterbar > #myinfra-search {
  height: 38px;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-radius: 20px 20px 20px 20px;
  width: 300px;
}

.users .filterbar>#myinfra-search:focus-visible {
  outline: none
}

.users .filterbar>#myinfra-search:focus-visible {
  outline: none
}

.users .filterbar>div>button:focus-visible {
  outline: none
}

.users .filterbar>div>button:focus {
  box-shadow: none
}

.users .filterbar>.btn-group>.btn{
  margin-left: -1px;
}

.users .filterbar>.btn-group>.btn:first-child {
  margin-left: 0;
}

.users .filterbar>.btn-group>.btn-outline-primary {
  color: #333;
  background-color: #fff;
  border-color: #adadad;
}

.users .filterbar>.btn-group>.btn-outline-primary:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.users table th {
  background-color: #f8f8f8;
  border-bottom-width: 2px;
}

.users table th:nth-child(1){
  width: 79px
}

.users table th:nth-child(2){
  width: 380px
}

.users table th:nth-child(3){
  width: 340px
}

.users table th:nth-child(4){
  width: 94px
}

.users table tr:last-child td{
  border: none
}

.users tr[title="loader"]:hover > *, .users tr[title="nodata"]:hover > *{
  --bs-table-accent-bg: white;
  color: black;
}