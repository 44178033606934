.body_submodal {
  position: absolute;
  height: 100%;
  width: 100%;
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  z-index: var(--bs-backdrop-zindex);
  background-color: var(--bs-backdrop-bg);
  padding: 0 !important; }

.container {
  padding: 0 !important; }

.modal_parent {
  /*position: relative;*/
  padding: 0 !important; }


