.login-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  width: fit-content;
  justify-content: center; }

.btn-social {
  margin: 10px !important;
  position: relative !important;
  padding-left: 44px !important;
  text-align: left !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important; }

.btn-social > :first-child {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 32px;
  line-height: 36px;
  font-size: 1.6em;
  text-align: center;
  border-right: 1px solid rgba(0, 0, 0, 0.2); }

.btn-facebook {
  color: #fff !important;
  background-color: rgb(59, 88, 152) !important; }

.btn-facebook:hover {
  color: #fff;
  background-color: rgb(48, 72, 125) !important;
  border-color: rgba(0, 0, 0, 0.2); }

.btn-google {
  color: #000 !important;
  background-color: #dd4b39 !important;}

.btn-google:hover {
  color: #fff;
  background-color: #c23321 !important;
  border-color: rgba(0, 0, 0, 0.2); }

.btn-nus {
  color: #215f95 !important;
  background-color: #f49644 !important;
  border-color: rgba(0, 0, 0, 0.2) !important; }

.btn-nus:hover {
  color: #215f95 !important;
  background-color: #E58B3D !important;
  border-color: rgba(0, 0, 0, 0.2) !important; }
