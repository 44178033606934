.customPagination {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-bottom: 10px;
  padding-top: 10px;
}

.pagination .page-item a, .pagination .page-item span {
  border-radius: 0 !important;
  border-color: #adadad
}

.pagination li.page-item:first-child>span,
.pagination li.page-item:first-child>a {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}
.pagination li.page-item:last-child>span,
.pagination li.page-item:last-child>a {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}