.modal-background {
  z-index: 1051;
  padding-top: 200px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; }

.modal-background .dialog {
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5); }

.modal-background .dialog .label {
  font-size: 16px;
  color: #337ab7;
  text-align: center;
  padding-bottom: 10px; }

.modal-background .dialog .buttons {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  gap: 10px; }
